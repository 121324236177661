<template>
  <div>
    <h2>{{ 'userManagement.users' | t }}</h2>
    <v-data-table
      must-sort
      v-model="selectedUsers"
      :headers="userHeaders"
      :items="allUsers"
      :pagination.sync="pagination0"
      :hide-actions="true"
      class="styled-table scroll"
      :no-data-text="'userManagement.noDataTexts.users' | t">

      <template slot="headerCell" slot-scope="props">
        <span
          @click.stop="addUserVisible = (props.header.addUserBtn ? true : addUserVisible) "
          :class="{'add-btn': props.header.addUserBtn}">
          <v-icon v-if="props.header.addUserBtn" style="font-size: 18px">add_circle</v-icon>
          <span>{{ (props.header.text ? `userManagement.tableHeaders.user.${props.header.text}` : '') | t }}</span>
        </span>
      </template>
      <template v-slot:items="props">
        <tr>
          <td>
            <v-checkbox
              class="check"
              :input-value="props.item.active"
              @click="entityToActivate = props.item; activationType = 'user'; checkActivation()"
              :disabled="props.item.id === userData.userId"
              primary
              readonly
              hide-details />
          </td>
          <td>
            <TooltipHolder :spanId="props.item.email" :moveLeft="-10" :tableData="true" :text="props.item.email" :size="17" />
          </td>
          <td>
            <TooltipHolder
              :tableData="true"
              :text="props.item.fullName"
              :size="22"
              :bold="true" />
          </td>
          <template v-if="userIsAdmin">
            <td>{{ `roles.${props.item.primaryRole}` | t }}</td>
            <td>
              <TooltipHolder
              :text="selectOrganization(props.item)"
              :size="27"
              :tableData="true" />
            </td>
          </template>
          <td>
            <TooltipHolder :tableData="true" :text="userPosition(props.item)" :size="20"  />
          </td>
          <td style="text-align: center;"><v-icon v-if="props.item.mainContactPerson">check</v-icon></td>
          <td>
            <v-btn icon @click.stop="userToUpdate = props.item; addUserVisible = true">
              <v-icon style="width: 50%;">edit</v-icon>
            </v-btn>

            <v-btn v-if="props.item.deletable && props.item.id !== userData.userId" icon @click.stop="deleteType = 'USER'; itemToDelete = props.item; showDeleteDialog = true">
              <v-icon style="width: 50%;">delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <br /><br />
    <template v-if="userIsAdmin">
      <h2>{{ 'userManagement.corporates' | t }}</h2>
      <v-data-table
        must-sort
        v-model="selectedCorporates"
        :headers="corporateHeaders"
        :pagination.sync="pagination1"
        :items="corporates"
        :hide-actions="true"
        class="styled-table scroll overflow-visible"
        :no-data-text="'userManagement.noDataTexts.corporates' | t">

        <template slot="headerCell" slot-scope="props">
          <span
            @click.stop="addCorporateVisible = (props.header.addCorporateBtn ? true : addCorporateVisible); corporateToUpdate = null"
            :class="{'add-btn': props.header.addCorporateBtn}">
            <v-icon v-if="props.header.addCorporateBtn" style="font-size: 18px">add_circle</v-icon>
            <span>{{ (props.header.text ? `userManagement.tableHeaders.corporate.${props.header.text}` : '') | t }}</span>
          </span>
        </template>

        <template v-slot:items="props">
          <tr>
            <td>
              <v-checkbox
                class="check"
                :input-value="props.item.active"
                @click="entityToActivate = props.item; activationType = 'corporate'; showActiveToggleDialog = true"
                primary
                readonly
                hide-details />
            </td>
            <td><TooltipHolder :tableData="true" :tableDataWidth="260" :text="props.item.name" :bold="true" :size="51"  /></td>
            <td>
              <TooltipHolder
                :spanId="props.item.name + 'volume'"
                :width="120"
                :moveLeft="60"
                :move-top="180"
                :tableData="false"
                :tableDataWidth="330"
                class="volumeTooltip"
                :text="formatBidAccordingly(props.item.transactionVolume[0].volumeEUR, { terms: { currency: 'EUR' } }) + ' ' + formatBidAccordingly(props.item.transactionVolume[0].volumeUSD, { terms: { currency: 'USD' } })"
                :tooltip-list="props.item.transactionVolume.slice(1,3).map(item => formatTransactionVolume(item))"
              />
            </td>
            <td>
              {{ formatTimestamp(props.item.paymentPeriodStart, 'DD.MM.') }}
            </td>
            <td>
              <TooltipHolder
                :spanId="props.item.name"
                :width="300"
                :moveLeft="-30"
                :tableData="true"
                :tableDataWidth="185"
                :text="props.item.panelLawFirms ? props.item.panelLawFirms.map(firm => lawFirms.find(lawFirm => lawFirm.id === firm.id).name).join(', ') : ''"
                :size="51" />
            </td>
            <td>
              <v-btn icon @click.stop="corporateToUpdate = props.item; addCorporateVisible = true">
                <v-icon style="width: 50%;">edit</v-icon>
              </v-btn>

              <template v-if="showSampleDataButton">
                <v-btn
                  icon
                  v-if="props.item.panelLawFirms.length >= 3"
                  @click.stop="generateLawFirmData = false; itemToUpdate = props.item; showSampleDataDialog = true">
                  <v-icon>add_circle</v-icon>
                </v-btn>
                <button class="v-btn v-btn--icon" v-else>
                  <div class="v-btn__content disabled-btn">
                    <TooltipHolder
                      :spanId="props.item.name + 'sample_data'"
                      :width="220"
                      icon="add_circle"
                      :tableData="false"
                      :moveTop="85"
                      :moveLeft="408"
                      :tooltip-text="$t('userManagement.sampleDataCreationDisabled')"
                      :size="100" />
                  </div>
                </button>
              </template>

              <v-btn v-if="props.item.deletable" icon @click.stop="deleteType = 'CORPORATE'; itemToDelete = props.item; showDeleteDialog = true">
                <v-icon style="width: 50%;">delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <br /><br />
      <h2>{{ 'userManagement.lawFirms' | t }}</h2>
      <v-btn>Download</v-btn>
      <v-data-table
        must-sort
        v-model="selectedLawFirms"
        :headers="lawFirmHeaders"
        :items="lawFirms"
        :pagination.sync="pagination2"
        :custom-sort="customSort"
        :hide-actions="true"
        class="styled-table scroll overflow-visible"
        :no-data-text="'userManagement.noDataTexts.lawFirms' | t">

        <template slot="headerCell" slot-scope="props">
          <span
            @click.stop="addLawFirmVisible = (props.header.addLawFirmBtn ? true : addLawFirmVisible)"
            :class="{'add-btn': props.header.addLawFirmBtn}">
            <v-icon v-if="props.header.addLawFirmBtn" style="font-size: 18px">add_circle</v-icon>
            <span>{{ (props.header.text ? `userManagement.tableHeaders.lawFirm.${props.header.text}` : '') | t }}</span>
          </span>
        </template>

        <template v-slot:items="props">
          <tr>
            <td>
              <v-checkbox
                class="check"
                :input-value="props.item.active"
                @click="entityToActivate = props.item; activationType = 'lawFirm'; showActiveToggleDialog = true"
                primary
                readonly
                hide-details />
            </td>
            <td><TooltipHolder tableData :text="props.item.name" :bold="true" /></td>
            <td><TooltipHolder tableData :text="props.item.street" /></td>
            <td><TooltipHolder tableData :text="props.item.city" /></td>
            <td><TooltipHolder tableData :spanId="props.item.country" :moveLeft="-10" :text="props.item.country" :size="19" /></td>
            <td><TooltipHolder tableData :text="props.item.taxId" /></td>
            <td>
              <v-btn icon @click.stop="lawFirmToUpdate = props.item; addLawFirmVisible = true">
                <v-icon style="width: 50%;">edit</v-icon>
              </v-btn>

              <template v-if="showSampleDataButton">
                <v-btn
                  icon
                  v-if="lawFirmIsAddedByThreeCorporates(props.item)"
                  @click.stop="generateLawFirmData = true; itemToUpdate = props.item; showSampleDataDialog = true">
                  <v-icon>add_circle</v-icon>
                </v-btn>
                <button class="v-btn v-btn--icon" v-else>
                  <div class="v-btn__content disabled-btn">
                    <TooltipHolder
                      :spanId="props.item.name + 'sample_data'"
                      :width="220"
                      icon="add_circle"
                      :tableData="false"
                      :moveTop="220"
                      :moveLeft="408"
                      :tooltip-text="$t('userManagement.sampleDataCreationDisabledLawFirm')"
                      :size="100" />
                  </div>
                </button>
              </template>

              <v-btn v-if="props.item.deletable" icon @click.stop="deleteType = 'LAWFIRM'; itemToDelete = props.item; showDeleteDialog = true">
                <v-icon style="width: 50%;">delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>

    <AddUserDialog
      v-model="addUserVisible"
      :userToUpdate="userToUpdate"
      @visibilityChange="userToUpdate = null"/>
    <AddLawFirmDialog
      v-model="addLawFirmVisible"
      :law-firm-to-update="lawFirmToUpdate"
      @visibilityChange="lawFirmToUpdate = null"/>
    <AddCorporateDialog
      v-model="addCorporateVisible"
      :corporate-to-update="corporateToUpdate"
      @visibilityChange="corporateToUpdate = null"/>
    <v-snackbar
      v-model="showSuccessMessage">
      <p style="color: white; margin-bottom: 0;">{{ $t(`userManagement.${snackbarKey}`, { name: updatedName }) }}</p>
      <v-btn
        icon
        @click="showSuccessMessage = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <ConfirmDialog
      v-if="showSampleDataDialog"
      :title="`userManagement.${generateLawFirmData ? 'lawFirmDialog' : 'corporateDialog'}.generateSampleDataTitle` | t"
      :text="$t(`userManagement.${generateLawFirmData ? 'lawFirmDialog' : 'corporateDialog'}.generateSampleDataText`, { itemName: itemToUpdate ? itemToUpdate.name : '' })"

      acceptButtonTextOrKey="buttons.submit"
      cancelButtonTextOrKey="buttons.cancel"

      @close="showSampleDataDialog = false; itemToUpdate = null"
      @submit="generateSampleDataLocal"
    />
    <ConfirmDialog
      v-if="showActiveToggleDialog"
      :title="$t(`userManagement.${entityToActivate.active ? 'deactivateDialog' : 'activateDialog'}.${activationType === 'user' ? 'userTitle' : (activationType === 'corporate' ? 'corporateTitle' : 'lawFirmTitle')}`)"
      :text="$t(`userManagement.${entityToActivate.active ? 'deactivateDialog' : 'activateDialog'}.text`, { name: entityToActivate.fullName || entityToActivate.name })"

      acceptButtonTextOrKey="buttons.submit"
      cancelButtonTextOrKey="buttons.cancel"

      @close="showActiveToggleDialog = false; entityToActivate = null"
      @submit="submitActiveToggle"
    />

    <ConfirmDialog
      v-if="showDeleteDialog"
      :title="$t(`userManagement.deleteDialog.${deleteType === 'USER' ? 'user' : (deleteType === 'LAWFIRM' ? 'lawfirm' : 'corporate')}.title`)"
      :text="$t(`userManagement.deleteDialog.${deleteType === 'USER' ? 'user' : (deleteType === 'LAWFIRM' ? 'lawfirm' : 'corporate')}.text`, { name: itemToDelete.name || itemToDelete.fullName })"

      acceptButtonTextOrKey="buttons.delete"
      cancelButtonTextOrKey="buttons.cancel"

      @close="showDeleteDialog = false; itemToDelete = null"
      @submit="deleteLocal"
    />

    <ConfirmDialog
      v-if="showCantActivateDialog"
      :title="$t(`userManagement.cantActivateTitle`)"
      :text="$t(`userManagement.cantActivateText`)"
      acceptButtonTextOrKey="buttons.ok"

      :hide-cancel="true"
      @close="showCantActivateDialog = false"
      @submit="showCantActivateDialog = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import AddUserDialog from './AddUserDialog'
import AddLawFirmDialog from './AddLawFirmDialog'
import TooltipHolder from '../../generalComponents/TooltipHolder'

import { usersHeaders, lawFirmHeaders, corporateHeaders } from './tableHeaders'
import AddCorporateDialog from './AddCorporateDialog'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'

export default {
  name: 'UserManagement',
  components: { AddCorporateDialog, AddLawFirmDialog, AddUserDialog, TooltipHolder, ConfirmDialog },
  data () {
    return {
      pagination0: {
        sortBy: 'email',
        rowsPerPage: -1
      },
      pagination1: {
        sortBy: 'name',
        rowsPerPage: -1
      },
      pagination2: {
        sortBy: 'fullName',
        rowsPerPage: -1
      },

      userHeaders: [],
      lawFirmHeaders: lawFirmHeaders,
      corporateHeaders: corporateHeaders,

      addUserVisible: false,
      addLawFirmVisible: false,
      addCorporateVisible: false,

      selectedUsers: [],
      selectedLawFirms: [],
      selectedCorporates: [],

      userToUpdate: null,
      lawFirmToUpdate: null,
      corporateToUpdate: null,
      itemToUpdate: null,

      showSuccessMessage: false,
      snackbarKey: '',
      updatedName: '',
      showSampleDataDialog: false,

      showActiveToggleDialog: false,
      entityToActivate: false,
      activationType: '',

      showCantActivateDialog: false,
      showDeleteDialog: false,
      itemToDelete: null,
      deleteType: null
    }
  },
  created () {
    if (!this.userIsAdmin && !this.userIsOrganizationAdmin) {
      this.$router.push({
        name: 'dashboardRfps'
      })
    }

    this.userHeaders = usersHeaders(this.userIsAdmin)

    this.loadAllUsers()

    if (this.userIsAdmin) {
      this.loadAllCorporates()
      this.loadAllLawFirms()
    }
  },
  computed: {
    ...mapGetters(['userIsAdmin', 'userIsOrganizationAdmin', 'formatBid', 'formatTimestamp', 'formatBidAccordingly', 'showSampleDataButton']),
    ...mapState({
      allUsers: state => state.admin.allUsers,
      lawFirms: state => state.lawFirms,
      corporates: state => state.corporates,
      userData: state => state.userData
    })
  },
  methods: {
    ...mapActions([
      'deleteUser',
      'deleteLawFirm',
      'deleteCorporate',
      'loadAllUsers',
      'loadAllLawFirms',
      'loadAllCorporates',
      'toggleUserActiveStatus',
      'toggleLawFirmActiveStatus',
      'toggleCorporateActiveStatus',
      'generateSampleData',
      'generateSampleDataLawFirm'
    ]),

    async deleteLocal () {
      if (this.deleteType === 'USER') {
        await this.deleteUser(this.itemToDelete.id)
      }

      if (this.deleteType === 'LAWFIRM') {
        await this.deleteLawFirm(this.itemToDelete.id)
      }

      if (this.deleteType === 'CORPORATE') {
        await this.deleteCorporate(this.itemToDelete.id)
      }

      this.showDeleteDialog = false
    },

    formatTransactionVolume (item) {
      const year = moment(item.periodStart).format('YYYY')
      const valueUSD = this.formatBidAccordingly(item.volumeUSD, { terms: { currency: 'USD' } })
      const valueEUR = this.formatBidAccordingly(item.volumeEUR, { terms: { currency: 'EUR' } })
      return `${year}: ${valueEUR} ${valueUSD} `
    },

    userPosition (user) {
      const key = `dropDownOptions.${user.primaryRole === 'ROLE_CORPORATE' ? 'CorporateUserPosition' : 'LawfirmUserPosition'}.${user.position}`
      if (this._i18n.i18next.exists(key)) {
        return this.$t(key)
      }

      return ''
    },

    checkActivation () {
      if (!this.entityToActivate.active && !this.entityToActivate.fullName) {
        this.showCantActivateDialog = true
      } else {
        this.showActiveToggleDialog = true
      }
    },

    lawFirmIsAddedByThreeCorporates (lawfirm) {
      return this.corporates.filter(corporate => corporate.panelLawFirms.length >= 2 && corporate.panelLawFirms.some(panelLawFirm => panelLawFirm.id === lawfirm.id)).length >= 3
    },

    async submitActiveToggle () {
      this.showActiveToggleDialog = false

      if (this.activationType === 'corporate') {
        await this.toggleCorporateActiveStatusLocal()
      } else if (this.activationType === 'user') {
        await this.toggleUserActiveStatusLocal()
      } else {
        await this.toggleLawFirmActiveStatusLocal()
      }
    },

    async generateSampleDataLocal () {
      this.showSampleDataDialog = false
      if (this.generateLawFirmData) {
        await this.generateSampleDataLawFirm(this.itemToUpdate.id)
      } else {
        await this.generateSampleData(this.itemToUpdate.id)
      }
      this.itemToUpdate = false
      this.snackbarKey = 'sampleDataCreationSuccess'
      this.showSuccessMessage = true
    },

    async toggleUserActiveStatusLocal () {
      const user = this.entityToActivate
      await this.toggleUserActiveStatus(user)
      this.snackbarKey = user.active ? 'activatedSuccess' : 'deactivatedSuccess'
      this.updatedName = user.fullName
      this.showSuccessMessage = true
    },

    async toggleCorporateActiveStatusLocal () {
      const corporate = this.entityToActivate
      await this.toggleCorporateActiveStatus(corporate)
      this.snackbarKey = corporate.active ? 'activatedSuccess' : 'deactivatedSuccess'
      this.updatedName = corporate.name
      this.showSuccessMessage = true
    },

    async toggleLawFirmActiveStatusLocal () {
      const lawFirm = this.entityToActivate
      await this.toggleLawFirmActiveStatus(lawFirm)
      this.snackbarKey = lawFirm.active ? 'activatedSuccess' : 'deactivatedSuccess'
      this.updatedName = lawFirm.name
      this.showSuccessMessage = true
    },

    selectOrganization (prop) {
      if (prop.lawFirm !== null) {
        return this.renderEntity('lawFirms', prop.lawFirm.id)
      } else if (prop.corporate !== null) {
        return this.renderEntity('corporates', prop.corporate.id)
      } else {
        return ' '
      }
    },

    renderEntity (type, id) {
      const entity = this[type].find(obj => obj.id === id)
      return entity ? entity.name : ''
    },

    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (!isDesc) {
          return a['name'] < b['name'] ? -1 : 1
        } else {
          return b['name'] < a['name'] ? -1 : 1
        }
      })
      return items
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .overflow-visible,
  .overflow-visible :deep(.v-table__overflow) {
    overflow: visible;
    overflow-x: visible !important;
  }

  .disabled-btn :deep(.v-icon) {
    color: $icon-gray !important;
  }

  .v-icon,
  :deep(.v-icon) {
    font-size: 24px !important;
  }

  .add-btn {
    height: 24px;
    width: 183px;
    display: inline-block;
    position: relative;

    span {
      position: absolute
      top: 4px;
      left: 30px
    }

    &:hover {
      cursor: pointer
    }
  }

  .check {
    margin-top: 0px;

  :deep(.v-input__slot) {
      border: none;
    }

    :deep(.v-icon) {
      color: $dark-gray !important;
    }
  }

  .scroll {
    max-width 1008px
    overflow-x: auto
  }

  :deep(table).v-table tbody td:not(:nth-child(1)) {
    padding 0 5px 0 12px
    word-break: break-word !important
  }

  :deep(table).v-table thead tr th {
    padding 0 12px
    max-width 180px
  }

  .volumeTooltip :deep(.tooltipText) {
    text-align left
  }

</style>

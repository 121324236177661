import apollo from '../../apollo'
import gql from 'graphql-tag'
import { lawFirm } from '../graphqlTags/rfpTags'

export default {

  async loadAllLawFirms ({ commit, state }) {
    const response = await apollo(state).query({
      query: gql`
        query allLawFirm {
          allLawFirms {
            ${lawFirm}
          }
        }
      `
    })

    commit('setAllLawFirms', response.data.allLawFirms)
  },

  async createLawFirm ({ dispatch, getters, state }, lawFirm) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation createLawFirm ($createLawFirmInput: CreateLawFirmInput!) {
          createLawFirm (createLawFirmInput: $createLawFirmInput) {
            id
          }
        }
      `,
      variables: {
        createLawFirmInput: lawFirm
      }
    })

    dispatch('loadAllLawFirms')
    return response.data.createLawFirm.id
  },

  async updateLawFirm ({ commit, getters, state }, lawFirmValue) {
    delete lawFirmValue.deletable
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation updateLawFirm($updateLawFirmInput: UpdateLawFirmInput!) {
          updateLawFirm (updateLawFirmInput: $updateLawFirmInput) {
            ${lawFirm}
          }
        }
      `,
      variables: {
        updateLawFirmInput: lawFirmValue
      }

    })

    commit('updateLawFirm', response.data.updateLawFirm)
  },

  async deleteLawFirm ({ dispatch, getters, state }, lawFirmId) {
    await apollo(state).mutate({
      mutation: gql`
        mutation deleteLawFirm ($id: ID!) {
          deleteLawFirm (id: $id)
        }
      `,
      variables: {
        id: lawFirmId
      }
    })

    dispatch('loadAllLawFirms')
  },

  async toggleLawFirmActiveStatus ({ dispatch }, lawFirm) {
    lawFirm.active = !lawFirm.active
    await dispatch('updateLawFirm', lawFirm)
  }

}

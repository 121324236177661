import apollo from '../../apollo'
import gql from 'graphql-tag'
import { vue } from '../../main'
import moment from 'moment'

export default {

  async loadAllUsers ({ commit, state }) {
    const response = await apollo(state).query({
      query: gql`
        query allUsers {
          allUsers {
            id
            active
            fullName
            email
            position
            phone
            mobile
            mainContactPerson
            language
            invitePending
            companyName
            deletable
            lawFirm {
              id
            }
            corporate {
              id
              transactionVolume {
                periodStart
                periodEnd
                volumeEUR
                volumeUSD
              }
            }
            primaryRole
          }
        }
      `
    })

    const { allUsers } = response.data
    const currentUser = allUsers.find(u => u.id === state.userData.userId)
    if (currentUser) {
      vue.$i18n.i18next.changeLanguage(currentUser.language)
      moment.locale(currentUser.language)
      commit('currentUser', currentUser)
    }
    commit('setAllUsers', allUsers)
  },

  async createUser ({ dispatch, state }, createUserInput) {
    createUserInput.corporate = createUserInput.corporate ? { id: createUserInput.corporate.id } : null
    createUserInput.lawFirm = createUserInput.lawFirm ? { id: createUserInput.lawFirm.id } : null
    delete createUserInput.invitePending
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation createUser($createUserInput: CreateUserInput!) {
          createUser (createUserInput: $createUserInput) {
            id
          }
        }
      `,
      variables: {
        createUserInput
      }
    })

    await dispatch('loadAllUsers')
    return response.data.createUser.id
  },

  async updateUser ({ dispatch, getters, state }, user) {
    user.corporate = user.corporate ? { id: user.corporate.id } : null
    user.lawFirm = user.lawFirm ? { id: user.lawFirm.id } : null
    delete user.deletable
    delete user.invitePending
    await apollo(state).mutate({
      mutation: gql`
        mutation updateUser($updateUserInput: UpdateUserInput!) {
          updateUser (updateUserInput: $updateUserInput) {
            id
          }
        }
      `,
      variables: {
        updateUserInput: user
      }

    })

    await dispatch('loadAllUsers')
    await dispatch('loadOptionLists', getters.userIsAdmin)
  },

  async deleteUser ({ dispatch, getters, state }, userId) {
    await apollo(state).mutate({
      mutation: gql`
        mutation deleteUser ($id: ID!) {
          deleteUser (id: $id)
        }
      `,
      variables: {
        id: userId
      }
    })

    dispatch('loadAllUsers')
  },

  async updatePassword ({ state, commit }, { oldPassword, newPassword }) {
    await apollo(state, false).mutate({
      mutation: gql`
          mutation changePassword($oldPassword: String!, $newPassword: String!) {
              changePassword (oldPassword: $oldPassword, newPassword: $newPassword)
          }
      `,
      variables: {
        oldPassword: oldPassword,
        newPassword: newPassword
      }
    })

    commit('setRequiresPasswordChange', false)
  },

  async resetPassword ({ state }, email) {
    await apollo(state).mutate({
      mutation: gql`
          mutation resetPassword($email: String!) {
              resetPassword (email: $email)
          }
      `,
      variables: {
        email: email
      }
    })
  },

  async updateMfaMethod ({ state }, { mfaMethod, secret }) {
    let request = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': state.authToken
      },
      body: JSON.stringify({ mfaMethod: mfaMethod, secret: secret }),
      method: 'POST'
    }

    const response = await fetch((process.env.JEST_WORKER_ID ? (process.env.JENKINS_RUN ? 'http://localhost:9090' : 'http://localhost:8080') : '') + `/rest/update-mfa`, request)

    if (response.status === 200) {
      return true
    } else {
      return false
    }
  },

  async inviteUser ({ state }, { email, lawFirmId }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation inviteUser($email: String!, $lawFirmId: ID!) {
            inviteUser (email: $email, lawFirmId: $lawFirmId) {
              id
              fullName
              invitePending
              email
            }
        }
      `,
      variables: {
        email: email,
        lawFirmId: lawFirmId
      }
    })

    return response.data.inviteUser
  },

  async toggleUserActiveStatus ({ dispatch }, user) {
    user.active = !user.active
    await dispatch('updateUser', user)
  },

  async signUpUser ({ state }, { createUserInput, organizationName }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation signup($createUserInput: CreateUserInput!, $organizationName: String!) {
          signup(createUserInput: $createUserInput, organizationName: $organizationName)
        }
      `,
      variables: {
        createUserInput,
        organizationName
      }
    })
  }

}

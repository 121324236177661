import apollo from '../../apollo'
import gql from 'graphql-tag'
import { corporate, terms } from '../graphqlTags/rfpTags'

export default {

  async loadAllCorporates ({ commit, state }) {
    const response = await apollo(state).query({
      query: gql`
        query allCorporates {
          allCorporates {
            ${corporate}
          }
        }
      `
    })

    commit('setAllCorporates', response.data.allCorporates)
  },

  async createCorporate ({ dispatch, state }, corporate) {
    corporate.panelLawFirms = corporate.panelLawFirms ? corporate.panelLawFirms.map(firm => ({ id: firm.id })) : []

    const response = await apollo(state).mutate({
      mutation: gql`
        mutation createCorporate ($corporateInput: CreateCorporateInput!) {
          createCorporate (corporateInput: $corporateInput) {
            id
          }
        }
      `,
      variables: {
        corporateInput: corporate
      }
    })

    dispatch('loadAllCorporates')
    return response.data.createCorporate.id
  },

  async updateCorporate ({ dispatch, state }, corporate) {
    delete corporate.rfps
    delete corporate.transactionVolume
    delete corporate.deletable
    corporate.panelLawFirms = corporate.panelLawFirms ? corporate.panelLawFirms.map(firm => ({ id: firm.id })) : []

    await apollo(state).mutate({
      mutation: gql`
        mutation updateCorporate ($corporateInput: UpdateCorporateInput!) {
          updateCorporate (corporateInput: $corporateInput) {
            id
          }
        }
      `,
      variables: {
        corporateInput: corporate
      }
    })

    dispatch('loadAllCorporates')
  },

  async deleteCorporate ({ dispatch, state }, corporateId) {
    await apollo(state).mutate({
      mutation: gql`
        mutation deleteCorporate ($id: ID!) {
          deleteCorporate (id: $id)
        }
      `,
      variables: {
        id: corporateId
      }
    })

    dispatch('loadAllCorporates')
  },

  async updateCorporateConfig ({ state }, { corporateConfig }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation updateCorporateConfig ($corporateConfig: UpdateCorporateConfig!) {
          updateCorporateConfig (corporateConfig: $corporateConfig) {
            id
          }
        }
      `,
      variables: {
        corporateConfig: corporateConfig
      }
    })
  },

  async loadOrganizationConfig ({ state }) {
    const response = await apollo(state).query({
      query: gql`
        query corporateConfig {
          corporateConfig {
            id
            defaultTerms {
              ${terms}
            }
          }
        }
      `
    })
    const { corporateConfig } = response.data
    return corporateConfig
  },

  async toggleCorporateActiveStatus ({ dispatch }, corporate) {
    corporate.active = !corporate.active
    await dispatch('updateCorporate', corporate)
  },

  async generateSampleData ({ state }, corporateId) {
    await apollo(state).mutate({
      mutation: gql`
          mutation generateDemoData ($corporateId: ID!) {
              generateDemoData (corporateId: $corporateId)
          }
      `,
      variables: {
        corporateId: corporateId
      }
    })
  },

  async generateSampleDataLawFirm ({ state }, lawFirmId) {
    await apollo(state).mutate({
      mutation: gql`
          mutation generateDemoDataLawFirm ($lawFirmId: ID!) {
              generateDemoDataLawFirm (lawFirmId: $lawFirmId)
          }
      `,
      variables: {
        lawFirmId: lawFirmId
      }
    })
  }

}

<template>
  <div>
    <div class="center-title">
      <h1>{{ 'newRFP.lawfirmSelection.lawfirmTitle' | t }}</h1>
      <!-- <v-icon class="info-icon">info</v-icon> -->
      <TooltipHolder
        class="info-icon"
        :titleText="$t('newRFP.lawfirmSelection.lawfirmTitle')"
        :spanId="$t('newRFP.lawfirmSelection.lawfirmTitle')"
        :width="150"
        :tooltipText="$t('tooltips.lawFirmSelectionTitleTooltip')"
        :moveTop="30"
        :icon="'info'"
        right
        summaryTooltip />
    </div>
    <v-divider style="margin-bottom: 30px;"></v-divider>

    <!-- Tabs small>
      <a>Recent</a>
      <a>Panel</a>
      <a :class="{active: activePanel === 'all'}" @click="activePanel = 'all'">All Firms</a>
      <a :class="{active: activePanel === 'selected'}" @click="activePanel = 'selected'">Selected</a>
    </Tabs -->

    <div class="content">
      <LawFirmTable
        :key="activePanel"
        :current-rfp="currentRfpOrDraft"
        :disabled="!isRfpFieldEditable(false)"
        :class="{missing: missingMandatoryFields.includes('lawFirmRelations')}"
        :only-selected="activePanel === 'selected'"/>
    </div>
  </div>
</template>

<script>
import LawFirmTable from './LawFirmTable'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LawFirmSelection',
  components: {
    LawFirmTable,
    TooltipHolder
  },
  data () {
    return {
      activePanel: 'all'
    }
  },
  computed: {
    ...mapGetters(['currentRfpOrDraft', 'isRfpFieldEditable']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    })
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  h1
    display inline-block

  .center-title
    font-size 32px
    margin-bottom 1.5em
    .info-icon
      font-size 20px
      margin-left 5px
      vertical-align middle

  >>> .info-icon
    font-size 20px
    margin-left 5px
    margin-bottom 4px
    vertical-align middle
    .v-icon
      font-size 20px !important
      color: #ababb5 !important
      vertical-align middle

  .content
    h2
      margin-top 1.5em
      margin-bottom 0.5em
    h2:first-child
      margin-top 0
</style>

export const questions = function () {
  return [
    {
      name: 'quality',
      subItems: [
        {
          rating: null,
          questionId: 0,
          questionKey: 'legalExpertise',
          category: 'QUALITY'
        },
        {
          rating: null,
          questionId: 1,
          questionKey: 'overallQualityOfWork',
          category: 'QUALITY'
        },
        {
          rating: null,
          questionId: 2,
          questionKey: 'workingAgain',
          category: 'QUALITY'
        }
      ]
    },
    {
      name: 'relationship',
      subItems: [
        {
          rating: null,
          questionId: 0,
          questionKey: 'relationship',
          category: 'RELATIONSHIP'
        },
        {
          rating: null,
          questionId: 1,
          questionKey: 'recommendation',
          category: 'RELATIONSHIP'
        },
        {
          rating: null,
          questionId: 2,
          questionKey: 'visibility',
          category: 'RELATIONSHIP'
        },
        {
          rating: null,
          questionId: 3,
          questionKey: 'relationships',
          category: 'RELATIONSHIP'
        }
      ]
    },
    {
      name: 'cost',
      subItems: [
        {
          rating: null,
          questionId: 0,
          questionKey: 'importanceOfPrice',
          category: 'COST'
        },
        {
          rating: null,
          questionId: 1,
          questionKey: 'clearPricingProcess',
          category: 'COST'
        },
        {
          rating: null,
          questionId: 2,
          questionKey: 'valueForMoney',
          category: 'COST'
        },
        {
          rating: null,
          questionId: 3,
          questionKey: 'communicationOfFees',
          category: 'COST'
        },
        {
          rating: null,
          questionId: 4,
          questionKey: 'metAgreedFees',
          category: 'COST'
        }
      ]
    },
    {
      name: 'service',
      subItems: [
        {
          rating: null,
          questionId: 0,
          questionKey: 'understandingOfBusiness',
          category: 'SERVICE'
        },
        {
          rating: null,
          questionId: 1,
          questionKey: 'understandingOfBusinessImprovements',
          category: 'SERVICE'
        },
        {
          rating: null,
          questionId: 2,
          questionKey: 'technicalDelivery',
          category: 'SERVICE'
        },
        {
          rating: null,
          questionId: 3,
          questionKey: 'serviceDelivery',
          category: 'SERVICE'
        },
        {
          rating: null,
          questionId: 4,
          questionKey: 'technologyPlatforms',
          category: 'SERVICE'
        },
        {
          rating: null,
          questionId: 5,
          questionKey: 'timelyDelivery',
          category: 'SERVICE'
        }
      ]
    },
    {
      name: 'addingValue',
      subItems: [
        {
          rating: null,
          questionId: 0,
          questionKey: 'valueBeyongLegalExpertise',
          category: 'ADDING_VALUE'
        }
      ]
    },
    {
      name: 'team',
      subItems: [
        {
          rating: null,
          questionId: 0,
          questionKey: 'staffing',
          category: 'TEAM'
        },
        {
          rating: null,
          questionId: 1,
          questionKey: 'availability',
          category: 'TEAM'
        },
        {
          rating: null,
          questionId: 2,
          questionKey: 'scoping',
          category: 'TEAM'
        },
        {
          rating: null,
          questionId: 3,
          questionKey: 'questions',
          category: 'TEAM'
        },
        {
          rating: null,
          questionId: 4,
          questionKey: 'conflicts',
          category: 'TEAM'
        },
        {
          rating: null,
          questionId: 5,
          questionKey: 'collaborations',
          category: 'TEAM'
        }
      ]
    }
  ]
}
